.container {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
}

.categoryName {
    display: flex;
    align-items: center;
}

.errorMessage {
    grid-column: 1 / -1;
    align-items: center;
    justify-content: center;
}

