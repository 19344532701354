.grid {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-column-gap: 15px;
}

.fullRow {
  grid-column: 1 / -1;
}

.halfRow {
  grid-column-start: span 10;
}

.selectProductRow {
  grid-column-start: 3;
  grid-column-end: 14;
  flex: 1;
}
/* 
.selectProductRowDelete {
  grid-column-start: 3;
  grid-column-end: 14;
} */

.selectProductDisplayRow {
  grid-column-start: span 5;
}
.selectProductQuantityRow {
  grid-column-start: span 2;
}

.addProductKey {
  grid-column-start: span 1;
}

.inline {
  display: flex;
  flex-direction: row;
}

.productSelected {
  background-color: lightgray;
}

.labelText {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.22px;
  text-align: left;
  line-height: 32px;
  margin-bottom: 0.2rem;
  margin-right: 0.2rem;
}

.list {
  list-style: decimal !important;
}

.numberOfProducts {
  grid-column-start: span 5;
  display: flex;
  align-items: flex-end;
  padding-bottom: 6px;
  font-family: 'Poppins-Bold';
  color: #000;
  font-size: 13px;
}

.dishes {
  grid-column-start: span 3;
}

.presentation {
  grid-column-start: span 6;
}

.price {
  grid-column-start: span 4;
}
