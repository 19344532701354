.container {
    height: 100%;
}

.text {
    font-family: 'Open Sans';
    color: black;
    font-weight: 500;
}

.radio {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    font-size: 14px;
    margin: 15px;
}

.radio .label {
    position: relative;
    display: block;
    float: left;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border: 2px solid #C8CCD4;
    border-radius: 100%;
    -webkit-tap-highlight-color: transparent;
}

.radio .label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #37AFFF;
    transform: scale(0);
    transition: all .2s ease;
    opacity: .08;
    pointer-events: none;
}

.radio:hover .label:after {
    transform: scale(3.6);
}

input[type="radio"]:checked + .label {
    border-color: #37AFFF;
}

input[type="radio"]:checked + .label:after {
    transform: scale(1);
    transition: all .2s cubic-bezier(.4, .9, .4, .9);
    opacity: 1;
}

.hidden {
    display: none;
}