.container {
    background-color: #c2c2c2;
}

.message {
    font-family: 'Roboto', "Open Sans",sans-serif;
    font-weight: normal;
    color: black;
    font-size: small;
    margin: 2px;
}