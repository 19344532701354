.input {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.32);
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 15.15px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.15px;
    line-height: 24px;
    padding: 6px 8px;
    width: 100%;
}

.disabled {
    background: #dddddd;
}

.invalid {
    border: 1px solid #f96868;
}

.input::placeholder {
    font-family: 'Poppins', sans-serif;
    font-size: 15.15px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.2);
    letter-spacing: 0.15px;
    line-height: 24px;
}