.addButton {
	background-color: transparent;
	border: 0px;
	color: #37afff;
	text-transform: uppercase;
}

.container {
	display: grid;
	grid-template-columns: repeat(20, 1fr);
	grid-column-gap: 15px;
	padding: 8px 5px;
	margin-bottom: 5px;
	background-color: #f5f5f5;
}

/* .container:hover .buttonContainer {
  display: flex;
}
.container:hover .key {
  margin-left: 0;
} */

.key {
	margin-left: 15px;
	font-family: 'Poppins-Bold';
	color: #000;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 50px;
	/* font-weight: bold; */
}

.buttonContainer {
	display: none;
}

.deleteButton {
	border: 0px;
	color: #37afff;
	font-size: 15px;
	text-transform: uppercase;
}
