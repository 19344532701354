.modal-row {
    margin-top: 10px;
}

.buttons {
    position: relative !important;
    display: flex;
    justify-content: flex-end;
}

.info {
    font-family: "Open Sans",sans-serif;
    font-weight: normal;
    color: #666666;
    font-size: 13px;
    margin-bottom: 20px;
}