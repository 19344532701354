.navbar__container {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12), inset 0 -1px 0 0 #eeeeee;
  padding-left: 42px;
  width: 100%;
}
.navbar__container__list {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.navbar__container__list__item {
  padding: 31px 12px 24px;
  font-family: 'Poppins';
  font-size: 13.5px;
  letter-spacing: 1.25px;
  color: #606060;
  cursor: pointer;
  box-sizing: border-box;
  text-transform: uppercase;
}
.navbar__container__list__item a {
  color: #606060;
  text-decoration: none;
}
.navbar__container__list__item:hover {
  background-color: #eeeeee;
  text-decoration: none;
}
.navbar__container__list__item--active {
  position: relative;
}
.navbar__container__list__item--active::after {
  content: ' ';
  border-bottom: 8px solid #37afff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.navbar__container .navbar__logo {
  margin-right: 61px;
}

.-dropdown-button__container {
  display: flex;
  align-items: center;
}

.-dropdown-button__icon {
  background: url('./icon_geo.svg') no-repeat;
  height: 24px;
  width: 24px;
  margin-top: 5px;
  margin-right: 8px;
}

a:hover {
  text-decoration: none;
}
