.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.buttons > button {
  margin: 2px;
}

.title {
  font-family: 'Poppins-Bold', sans-serif;
  font-size: 18.94px;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.25px;
}
