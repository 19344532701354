.container {
    display: grid;
    grid-template: 1fr / repeat(2, 1fr);
    background-color: gainsboro;
    height: 100%;
    padding-top: 8px;
}

.button {
    padding: 3px 17px 5px;
    background-color: #37afff;
    border-radius: 50px;
    font-family: 'Poppins';
    letter-spacing: 1.6px;
    text-align: center;
    outline: 0;
    cursor: pointer;
    border: none;
}

.button:focus {
    outline: 0;
}

.button__outline {
    background-color: #ffffff;
    border: 1px solid #37afff;
    color: #37afff;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.invisible {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    width: 1px;
    height: 1px;
}