.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.label {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.22px;
    text-align: left;
    line-height: 32px;
    margin-bottom: 0.2rem;
    margin-right: 0.2rem;
}

.invalid {
    color: #f96868;
}
