.modal {
  padding: 15px 20px;
  max-width: 2000px;
  margin: 0 auto;
  box-shadow: none;
}

.modal__title {
  font-family: Poppins-Bold;
  font-size: 18.94px;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.25px;
}

.nilusText {
  color: black;
  font-weight: 400;
  font-family: 'Open Sans';
}

.gridContainer {
  display: grid;
  justify-content: space-around;
  grid-row-gap: 15px;
}

.subtitle {
  grid-column-start: 1;
  grid-column-end: 3;
}

.actionSelect {
  grid-column-start: 1;
  grid-column-end: 2;
}

.sheetButton {
  justify-content: center;
  align-items: flex-end;
  grid-column-start: 2;
  grid-column-end: 3;
}

.fileSelect {
  grid-column-start: 1;
  grid-column-end: 3;
}
