body {
  background: #ffffff !important;
}

body.modal-open::after {
  top: 0;
  content: ' ';
  min-height: 150vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 1600;
}

.page {
  min-height: 100vh;
}

.animated {
  -webkit-animation-fill-mode: initial !important;
  animation-fill-mode: initial !important;
}

.modal-header {
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
}

.tippy-tooltip {
  text-align: left !important;
}

.tippy-tooltip .enter {
  border-radius: 0 !important;
}
