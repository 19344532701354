.container {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-column-gap: 10px;
  padding: 0px 5px;
  margin-top: 10px;
}

.wholeRow {
  grid-column: 1 / -1;
}

.halfRow {
  grid-column-start: span 12;
}

.oneThirdRow {
  grid-column-start: span 8;
}

.oneFourthRow {
  grid-column-start: span 6;
}

.oneCol {
  grid-column-start: span 1;
}

.fourCols {
  grid-column-start: span 4;
}

.nineCols {
  grid-column-start: span 9;
}

.elevenCols {
  grid-column-start: span 11;
}
