.container {
  background-color: #f5f5f5;
  padding: 10px;
}

.container > h5,
h4,
ul,
li {
  font-family: 'Poppins', sans-serif;
}

.list_item {
  list-style-image: url('https://res.cloudinary.com/dcnzfzgxm/image/upload/c_scale,w_10/v1592308627/Image-Source-PlusPNG_ljt1s7.png');
  margin-left: 5px;
}
